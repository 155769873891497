<template>
	<div id="app">
		<div class="top-title-div">
			<div class="top-div">
				<div class="logo-div"></div>
				<a-menu :default-selected-keys="['1']" mode="horizontal" :selected-keys="[currKey]" @click="handleClick">
					<template v-for="item in menuData">
						<a-menu-item v-if="!item.children" :key="item.key">
							{{ item.name }}
						</a-menu-item>
						<a-sub-menu v-else :key="item.key">
							<span slot="title">
								<span>{{ item.name }}</span>
							</span>
							<a-menu-item v-for="childItem in item.children" :key="childItem.key">{{ childItem.name }}</a-menu-item>
						</a-sub-menu>
					</template>
				</a-menu>
			</div>
		</div>
		<div ref="routerMainEl" :style="{ height: clientHeight + 'px' }" class="body-div">
			<router-view />
		</div>
	</div>
</template>

<script>
import { menuData, getMenuByKey }	from './data'
import store from './store'
import router from './router'

export default {
  name: 'App',
	data() {
		return {
			theme: 'dark',
			menuData,
			clientHeight: undefined,
			current: '1',
		};
	},
	computed: {
		currKey() {
			return store.state.currkey;
		}
	},
	methods: {
		jumpto() {
			this.$router.push('/news');
		},
		changeTheme(checked) {
			this.theme = checked ? 'dark' : 'light';
		},
		handleClick(e) {
			const menu = getMenuByKey(e.key,menuData);
			if(menu) {
				this.$router.push(menu.path);
				this.current = e.key
			}
		},
		handleResize() {
			this.clientHeight = document.documentElement.clientHeight-82;
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	mounted() {
		router.afterEach(() => {
			this.$refs.routerMainEl.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		});
		window.addEventListener('resize', this.handleResize);
		this.handleResize();
	}
}
</script>

<style lang="less" scoped>
.body-div {
	overflow-y: auto;
}
.logo-div {
	background-image: url('@/assets/img/logo.png');
	background-size: 180px 65px;
	width: 180px;
	height: 82px;
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 100px;
}
.top-div {
	width: 70%;
	display: flex;
}
.top-title-div {
	display: flex;
	height: 82px;
	align-items: center;
	justify-content: center;
}
.ant-menu-horizontal {
	line-height: 82px;
}
.ant-menu {
	font-size: 14px;
}
.ant-menu-horizontal > .ant-menu-item-active {
	background-color: rgba(24, 144, 255, 0.1) !important;
}
</style>
