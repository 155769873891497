import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

const notFoundRouter = {
	path: "*",
	// redirect: "/404",
	hidden: true,
};

export default new Router({
	mode: "history",
	routes: [
		//默认
		{
			path: "/",
			redirect: "/dashbord"
		},
		{
			path: "/dashbord",
			name: "dashbord",
			component: (resolve) => require(["@/page/dashboard"], resolve),
		},
		{
			path: "/productCenter",
			name: "productCenter",
			component: (resolve) => require(["@/page/productCenter/productCenter"], resolve),
			children: [{
					path: "/productCenter/reclaimer",
					name: "reclaimer",
					component: (resolve) => require(["@/page/productCenter/reclaimer"], resolve),
				},
				{
					path: "/productCenter/crane",
					name: "crane",
					component: (resolve) => require(["@/page/productCenter/crane"], resolve),
				},
				{
					path: "/productCenter/load",
					name: "load",
					component: (resolve) => require(["@/page/productCenter/load"], resolve),
				},
				{
					path: "/productCenter/loadBuild",
					name: "loadBuild",
					component: (resolve) => require(["@/page/productCenter/loadBuild"], resolve),
				},
				{
					path: "/productCenter/unload",
					name: "unload",
					component: (resolve) => require(["@/page/productCenter/unload"], resolve),
				},
				{
					path: "/productCenter/grainSilo",
					name: "grainSilo",
					component: (resolve) => require(["@/page/productCenter/grainSilo"], resolve),
				},
			]
		},
		{
			path: "/bulkSys",
			name: "bulkSys",
			component: (resolve) => require(["@/page/bulkSys/bulkSys"], resolve),
			children: [{
					path: "/bulkSys/aps",
					name: "aps",
					component: (resolve) => require(["@/page/bulkSys/aps"], resolve),
				},
				{
					path: "/bulkSys/ecs",
					name: "ecs",
					component: (resolve) => require(["@/page/bulkSys/ecs"], resolve),
				},
				{
					path: "/bulkSys/dms",
					name: "dms",
					component: (resolve) => require(["@/page/bulkSys/dms"], resolve),
				},
				{
					path: "/bulkSys/mes",
					name: "mes",
					component: (resolve) => require(["@/page/bulkSys/mes"], resolve),
				},
				{
					path: "/bulkSys/digital",
					name: "digital",
					component: (resolve) => require(["@/page/bulkSys/digital"], resolve),
				}
			],
		},
		{
			path: "/solution",
			name: "solution",
			component: (resolve) => require(["@/page/solution/solution"], resolve),
			children: [{
					path: "/solution/bulkDig",
					name: "bulkDig",
					component: (resolve) => require(["@/page/solution/bulkDig"], resolve),
				},
				{
					path: "/solution/digTwin",
					name: "digTwin",
					component: (resolve) => require(["@/page/solution/digTwin"], resolve),
				},
				{
					path: "/solution/smartPort",
					name: "smartPort",
					component: (resolve) => require(["@/page/solution/smartPort"], resolve),
				},
				{
					path: "/solution/sysInter",
					name: "sysInter",
					component: (resolve) => require(["@/page/solution/sysInter"], resolve),
				},
			],
		},
		{
			path: "/serviceAndsuport",
			name: "serviceAndsuport",
			component: (resolve) => require(["@/page/serviceAndsuport"], resolve),
		},
		{
			path: "/contactUs",
			name: "contactUs",
			component: (resolve) => require(["@/page/contactUs"], resolve),
		},
		{
			path: "/news",
			name: "news",
			component: (resolve) => require(["@/page/news/news"], resolve),
			children: [{
					path: "/news/newsList",
					name: "newsList",
					component: (resolve) => require(["@/page/news/newsList"], resolve),
				},
				{
					path: "/news/newsEdit",
					name: "newsEdit",
					component: (resolve) => require(["@/page/news/newsEdit"], resolve),
				},
				{
					path: "/news/newsDetail",
					name: "newsDetail",
					component: (resolve) => require(["@/page/news/newsDetail"], resolve),
				},
			],
		},
		{
			path: "/404",
			name: "404",
			component: (resolve) => require(["@/page/exception/404"], resolve),
		},
		notFoundRouter,
	]
});