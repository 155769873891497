export const menuData= [
	{
		key: '1',
		path: '/dashbord',
		name: '首页'
	},
	{
		key: '2',
		name: '产品中心',
		path: '/productCenter',
		children: [
			{
				key: '21',
				name: '堆取料机全自动化',
				path: '/productCenter/reclaimer',
			},{
				key: '22',
				name: '抓斗门机全自动化',
				path: '/productCenter/crane',
			},{
				key: '23',
				name: '装船机全自动化',
				path: '/productCenter/load',
			},{
				key: '24',
				name: '卸船机全自动化',
				path: '/productCenter/unload',
			},{
				key: '25',
				name: '装车楼全自动化',
				path: '/productCenter/loadBuild',
			},{
				key: '26',
				name: '粮食筒仓全自动化',
				path: '/productCenter/grainSilo',
			}
		]
	},
	{
		key: '3',
		name: '散杂货生态系统',
		path: '/bulkSys',
		children: [
			{
				key: '31',
				name: 'APS智慧调度算法',
				path: '/bulkSys/aps',
			},{
				key: '32',
				name: 'ECS设备协同控制系统',
				path: '/bulkSys/ecs',
			},{
				key: '33',
				name: 'DMS全流程自动化中台',
				path: '/bulkSys/dms',
			}
		]
	},
	{
		key: '4',
		name: '解决方案',
		path: '/solution',
		children: [
			{
				key: '41',
				name: '干散货码头数智化',
				path: '/solution/bulkDig'
			},
			{
				key: '42',
				name: '智慧港口',
				path: '/solution/smartPort'
			},
			{
				key: '43',
				name: '智慧港航',
				path: '/solution/sysInter'
			},
			{
				key: '44',
				name: '数字孪生',
				path: '/solution/digTwin'
			},
		]
	},
	{
		key: '5',
		path: '/serviceAndsuport',
		name: '服务与支持'
	},
	{
		key: '6',
		path: '/contactUs',
		name: '联系我们'
	},
]

export const getMenuByPath = (path, menuData)=>{
	if(menuData instanceof Array) {
		for(let i=0,len=menuData.length;i<len;i++){
			const item = menuData[i]
			if(item.path==path) {
				return item;
			}
			if(item.children) {
				const child = getMenuByPath(path,item.children)
				if(child) {
					return child
				}
			}
		}
	}
}

export const getMenuByKey = (key, menuData)=>{
	if(menuData instanceof Array) {
		for(let i=0,len=menuData.length;i<len;i++){
			const item = menuData[i]
			if(item.key==key) {
				return item;
			}
			if(item.children) {
				const child = getMenuByKey(key,item.children)
				if(child) {
					return child
				}
			}
		}
	}
}