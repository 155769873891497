import Vue from 'vue'
import router from './router'
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/antd.css';
import store from './store'
import './permission'
import VueLazyload from 'vue-lazyload'
const loadingSrc = require('@/assets/loading.gif')
const errorSrc = require('@/assets/loading.gif')
Vue.use( // 注册方式二，自定义配置相关图片和监听方法类型
  VueLazyload,
  {
		preLoad: 1.3, // 默认 1.3
		attempt: 1, // 默认 3
    // the default is ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend']
		listenEvents: ['scroll'],
		// 设置懒加载时的占位图
		loading: loadingSrc,
		// 设置图片加载失败时的占位图
		error: errorSrc
  }
)
Vue.use(Antd);
Vue.config.productionTip = false
new Vue({
	router,
	store,
  render: h => h(App),
}).$mount('#app')
