import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
	namespaced: true,
  state: {
    currkey: undefined, 
  },
	getters: {
		currkey: state => {
			return state.currkey?state.currkey: 1
		}
	},
  mutations: {
    setCurrkey(state, data) {
      state.currkey = data
    },
  },
  actions: {
		
  },
})
