import router from './router'
import store from './store'
import { menuData, getMenuByPath } from './data'

router.beforeEach((to, from, next) => {
	const route = getMenuByPath(to.path, menuData)
	if(route) {
		store.commit('setCurrkey',route.key)
	}else {
		store.commit('setCurrkey','1')
	}
	next();
});